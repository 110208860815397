import React from "react";
import { Modal } from "react-bootstrap";

// import { useDispatch } from "react-redux";

import { useThemeColor } from "../../../hooks/useThemeColor";
import { ImCross } from "react-icons/im";
// import { rootUrl, userKey } from "../../../App";
import LotteryHistory from "./LotteryHistory";

const LotteryHistoryModal = ({ show, close, data, }) => {
    // const dispatch = useDispatch();

    const { colorMode } = useThemeColor();

    return (
        <Modal className="lottery-place-modal p-0 m-0" fullscreen={false} show={show}>
            <Modal.Body className="d-flex justify-content-center" style={{ padding: 0, backgroundColor: (colorMode === "dark") ? "var(--dark)" : "var(--light-blue)" }}> {/* .modal-content */}
                <div className="w-100">
                    <div className="px-0 golden-background fw-bold" style={{ borderRadius: "5px 5px 0 0" }}>
                        <div className="d-flex align-items-center justify-content-center p-1" style={{ position: "relative" }}>
                            <div className="d-flex flex-column">
                                <p style={{ color: "var(--dark)", textAlign: "center", marginBottom: 0, }}
                                    className="mb-0 pb-0 text-center text-uppercase place-modal-main-title">
                                    {`My Bets`}
                                </p>
                            </div>

                            <button onClick={close} style={{ color: "var(--dark)", backgroundColor: "transparent", position: "absolute", top: "12%", right: "0px" }} type="button" className="border-0 d-block d-md-block" data-bs-dismiss="modal" aria-label="Close">
                                <ImCross />
                            </button>
                        </div>
                    </div>

                    <div className="row gx-0 d-flex align-items-start justify-content-center"
                    >
                        <div className="col-12 lottery-history-items"
                        >
                            <LotteryHistory />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LotteryHistoryModal;