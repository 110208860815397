import React, { useEffect, useState } from "react";
import "./MovableHome.css";
import { useNavigate } from "react-router-dom";

import Draggable from "react-draggable";

const home = process.env.PUBLIC_URL + "/Assets/Images/Icons/DraggableHomeIcon/home-icon.svg";


const MovableHome = () => {

    const navigate = useNavigate();
    // const location = useLocation();
    const [bounds, setBounds] = useState({ left: 0, top: 0, right: 0, bottom: 0 });

    const [isDragging, setIsDragging] = useState(true);

    const eventControl = (event) => {
        if (event.type === "mousemove" || event.type === "touchmove") {
            setIsDragging(true);
        }

        if (event.type === "mouseup" || event.type === "touchend") {
            setTimeout(() => {
                setIsDragging(false);
            }, 1000);
        }
    };

    // const eventHandler = (e, data) => {
    //     console.log("Event Type", e.type);
    //     console.log({ e, data });
    // }

    const handleMovableHome = () => {
        // if (location.pathname !== "/") {
        if (!isDragging) {
            setTimeout(() => {
                navigate("/");
            }, 500);
            setIsDragging(true);
        }
    }
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    useEffect(() => {
        const calculateBounds = () => {

            const baseLeftPosition = 13;
            const leftPosition = screenWidth >= 769 ? baseLeftPosition : baseLeftPosition;

            const baseRightPosition = 13;
            const rightPosition = screenWidth >= 769 ? ((screenWidth * 0.29) - baseRightPosition) : ((screenWidth * 0.9) - (baseRightPosition + baseLeftPosition));

            const topPosition = -(screenHeight * 0.9);

            const baseBottomPosition = 13;
            const bottomPosition = -baseBottomPosition;

            setBounds({
                left: leftPosition,
                top: topPosition,
                right: rightPosition,
                bottom: bottomPosition
            });
        };

        calculateBounds();

        window.addEventListener("resize", calculateBounds);

        return () => {
            window.removeEventListener("resize", calculateBounds);
        };
    }, [screenWidth, screenHeight]);

    return (
        <Draggable
            handle=".handle"
            defaultPosition={{ x: 13, y: -13 }}
            bounds={bounds}
            onMouseDown={() => handleMovableHome()}
            // onStart={eventControl}
            onDrag={eventControl}
            onStop={eventControl}
        >
            <div
                className="draggable-home-area"
                onClick={() => !isDragging && handleMovableHome()}
            >
                <div className="handle">
                    <img className="draggable-home" src={home} alt="home" width={"45px"} height={"45px"} />
                </div>
            </div>
        </Draggable>
    );
};

export default MovableHome;