import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import { useDispatch } from "react-redux";

import { useThemeColor } from "../../../hooks/useThemeColor";
import { FaPlus } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { rootUrl, userKey } from "../../../App";
import { showCustomSweetAlert } from "../../../Redux/customSweetAlertSlice";

const LotteryPlaceModal = ({ show, close, data, }) => {
    const dispatch = useDispatch();

    const { colorMode } = useThemeColor();

    const [tableList, setTableList] = useState([
        {
            id: 1,
        },
    ]);

    const [listTotal, setListTotal] = useState(0.00);
    const [discountAmount, setDiscountAmount] = useState(0.00);
    const [betAmount, setBetAmount] = useState(0.00);

    const betNumbersLength = +((data?.total_number * data?.number_digit) + (data?.total_number - 1));

    useEffect(() => {
        setTableList([
            {
                id: 1,
            },
            {
                id: 2,
            },
            {
                id: 3,
            },
            {
                id: 4,
            },
            {
                id: 5,
            },
            {
                id: 6,
            },
            {
                id: 7,
            },
            {
                id: 8,
            },
            {
                id: 9,
            },
            {
                id: 10,
            },
        ]);
    }, [data?.id]);

    useEffect(() => {
        let total = 0;
        tableList?.map(item => total = total + (item.d_stake_bet ? item.d_stake_bet : 0) + (item.r_stake_bet ? item.r_stake_bet : 0));
        setListTotal(total);

        let discount = (+data?.discount_percentage > 0 && listTotal) ?
            ((listTotal.toFixed(2) * data?.discount_percentage) / 100).toFixed(2)
            : "00.00"

        setDiscountAmount(discount);

        let bet = (+data?.discount_percentage > 0 && listTotal)
            ? (listTotal.toFixed(2) - ((listTotal.toFixed(2) * data?.discount_percentage) / 100).toFixed(2)).toFixed(2)
            : ((+data?.discount_percentage === 0) && listTotal) ? listTotal.toFixed(2) : "00.00"

        setBetAmount(bet);

    }, [tableList, listTotal]);

    const formatWithHyphens = (input, number_digit) => {
        // Remove all existing hyphens to simplify the length calculation
        let valueWithoutHyphens = input.replace(/-/g, "");
        let formattedValue = "";

        // Iterate over the string and add a hyphen every `number_digit` characters
        for (let i = 0; i < valueWithoutHyphens?.length; i++) {
            if (i > 0 && i % number_digit === 0) {
                formattedValue += "-";
            }
            formattedValue += valueWithoutHyphens[i];
        }

        return formattedValue;
    }

    function isValidInput(input) {
        return /^[\d-]*$/.test(input);
    }

    // getting input data to post a bet
    const getInputDataFunction = (e, id) => {
        if (e.target.name === "d_stake_bet" || e.target.name === "r_stake_bet") {
            let selectItem = tableList?.find(item => item?.id === id);

            if ((selectItem.bet_numbers)?.length === (betNumbersLength)) {
                let newData = { ...selectItem };
                newData[e.target.name] = +e.target.value;
                // console.log(newData);
                let list = [...tableList]
                list[id - 1] = newData;
                setTableList(list);
            } else {
                // dispatch(setCustomAlertTrue({ massage: `Please Input Bet Number`, type: "danger" }));
                dispatch(showCustomSweetAlert({
                    type: "error",
                    message: `Please select ${data?.total_number} balls.`,
                }));
                return;
            }
        }
        if (e.target.name === "bet_numbers") {
            // console.log(e.target.value);
            if (isValidInput(e.target.value)) {
                const formattedValue = formatWithHyphens(e.target.value, data?.number_digit);

                if ((e.target.value).length < (betNumbersLength)) {
                    let list = [...tableList]
                    list[id - 1] = {
                        id: id,
                        bet_numbers: formattedValue,
                        d_stake_bet: "",
                        r_stake_bet: "",
                    };
                    setTableList(list);
                } else {
                    let selectItem = tableList?.find(item => item?.id === id);
                    let newData = { ...selectItem };
                    newData[e.target.name] = e.target.value;
                    // console.log(newData)
                    let list = [...tableList];
                    list[id - 1] = newData;
                    setTableList(list);
                }
            }
        }
    };

    const checkDigits = (e, id) => {
        let selectItem = tableList?.find(item => item?.id === id);
        if ((selectItem.bet_numbers)?.length !== (betNumbersLength)) {
            // dispatch(setCustomAlertTrue({ massage: `Enter Bet Number : ${data?.number_digit} digit`, type: "danger" }));
            dispatch(showCustomSweetAlert({
                type: "error",
                message: `Please select ${data?.total_number} balls.`,
            }));
            return;
        }
    }

    const checkAmount = (e, id) => {
        let selectItem = tableList?.find(item => item?.id === id);

        if ((+e.target.value > +data.max_bet_amount) || (+e.target.value < +data?.min_bet_amount)) {
            // dispatch(setCustomAlertTrue({ massage: `Minimum Amount : ${selectDropdownData.min_bet_amount}`, type: "danger" }));
            dispatch(showCustomSweetAlert({
                type: "error",
                message: `Min - Max Amount : ${data?.min_bet_amount} - ${data?.max_bet_amount}`,
            }));
            return;
            // let newData = { ...selectItem };
            // newData[e.target.name] = "";
            // // console.log(newData)
            // let list = [...tableList]
            // list[id - 1] = newData;
            // setTableList(list);
        } else {
            if ((selectItem.bet_numbers)?.length === (betNumbersLength)) {
                let newData = { ...selectItem };
                newData[e.target.name] = +e.target.value;
                // console.log(newData)
                let list = [...tableList]
                list[id - 1] = newData;
                setTableList(list);
            } else {
                // dispatch(setCustomAlertTrue({ massage: `Please Input Bet Number`, type: "danger" }));
                dispatch(showCustomSweetAlert({
                    type: "error",
                    message: `Please select ${data?.total_number} balls.`,
                }));
                return;
                // let newData = { ...selectItem };
                // newData[e.target.name] = "";
                // // console.log(newData)
                // let list = [...tableList]
                // list[id - 1] = newData;
                // setTableList(list);
            }
        }
    }

    const addRowInTable = () => {
        let data = [...tableList, {
            id: tableList?.length + 1
        }]
        setTableList(data)
    }

    const filterProperties = (arr) => {
        return arr.map(obj => {
            // Create a new object without id
            const { id, d_stake_bet, r_stake_bet, ...rest } = obj;

            // Include d_stake_bet only if it is not 0
            if (d_stake_bet !== 0) {
                rest.d_stake_bet = d_stake_bet;
            }

            // Include r_stake_bet only if it is not 0
            if (r_stake_bet !== 0) {
                rest.r_stake_bet = r_stake_bet;
            }

            return rest;
        });
    }

    const lotteryPlaceConfirmationFunction = (e) => {
        e.preventDefault();

        let finalData = [];

        tableList?.map((item) => {
            if ((item.bet_numbers)?.length === (betNumbersLength)) {
                if (item?.d_stake_bet) {
                    if (!item.r_stake_bet) {
                        item.r_stake_bet = 0;
                    }
                } else {
                    item.d_stake_bet = 0
                    if (!item.r_stake_bet) {
                        item.r_stake_bet = 0;
                        return null;
                    }
                }
                item.d_stake_bet = +item.d_stake_bet;
                item.r_stake_bet = +item.r_stake_bet;

                finalData.push(item);
            }

            return null;
        })

        const filteredArray = filterProperties(finalData);
        // console.log(filteredArray);

        let stringValue = localStorage.getItem(userKey);
        if (stringValue !== null) {
            // dispatch(setInnerLoaderTrue());
            let value = JSON.parse(stringValue);
            fetch(`${rootUrl}/lottery/bet-place/${data?.id}/`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Token ${value?.value}`,
                },
                body: JSON.stringify(filteredArray)
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    if (data.status === "failed") {
                        // throw data.error;
                        dispatch(showCustomSweetAlert({
                            type: "error",
                            message: `${data?.errors}`,
                        }));
                        return;
                    }
                    if (data.status === "ok") {
                        // dispatch(setCustomAlertTrue({ massage: "Bet Placed Successfully", type: "success" }));
                        dispatch(showCustomSweetAlert({
                            type: "success",
                            message: `${data?.message}`,
                        }));
                        return;
                    }

                    setTableList([
                        {
                            id: 1,
                        },
                        {
                            id: 2,
                        },
                        {
                            id: 3,
                        },
                        {
                            id: 4,
                        },
                        {
                            id: 5,
                        },
                    ])
                })
                .catch(err => {
                    // console.log(err);
                    err.json().then(text => {
                        if (text?.error) {
                            // dispatch(setCustomAlertTrue({ massage: `${text?.error}`, type: "danger" }));
                            dispatch(showCustomSweetAlert({
                                type: "error",
                                message: `${text?.error}`,
                            }));
                            return;
                        }
                        if (text?.detail === "Invalid token.") {
                            // dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                    // dispatch(setCustomAlertTrue({ massage: `${err.statusText}`, type: "danger" }));
                    dispatch(showCustomSweetAlert({
                        type: "error",
                        message: `${err.statusText}`,
                    }));
                    return;
                })
                .finally(
                // () => { dispatch(setInnerLoaderFalse()) }
            )
        }
    };

    return (
        <Modal className="lottery-place-modal p-0 m-0" fullscreen={false} show={show}>
            <Modal.Body className="d-flex justify-content-center" style={{ padding: 0, backgroundColor: (colorMode === "dark") ? "var(--dark)" : "var(--white)" }}> {/* .modal-content */}
                <div className="w-100">
                    <div className="px-0 golden-background fw-bold" style={{ borderRadius: "5px 5px 0 0" }}>
                        <div className="d-flex align-items-center justify-content-center p-1" style={{ position: "relative" }}>
                            <div className="d-flex flex-column">
                                <p style={{ color: "var(--dark)", textAlign: "center", marginBottom: 0, }}
                                    className="mb-0 pb-0 text-center place-modal-main-title">
                                    {data?.game_type?.name}
                                </p>

                                <p className="mb-0 pb-0 red-text">
                                    <small>
                                        Min - Max Amount : {data?.min_bet_amount} - {data?.max_bet_amount}
                                    </small>
                                </p>
                            </div>

                            <button onClick={close} style={{ color: "var(--dark)", backgroundColor: "transparent", position: "absolute", top: "12%", right: "0px" }} type="button" className="border-0 d-block d-md-block" data-bs-dismiss="modal" aria-label="Close">
                                <ImCross />
                            </button>
                        </div>
                    </div>

                    <div className="my-2 px-2 row gx-0 d-flex align-items-end justify-content-between"
                    >
                        <div className="col-11"
                        >
                            <table className="">
                                <tbody
                                    className="d-flex flex-column gap-2"
                                    style={{ color: (colorMode === "dark") ? "var(--white)" : "var(--dark)", }}
                                >
                                    {
                                        tableList?.map((item, index) =>
                                            <tr key={item?.id} className=""
                                            >
                                                <td className="text-end"
                                                    style={{
                                                        width: "3ch",
                                                    }}
                                                >{item?.id}.</td>
                                                <td className="text-center p-0">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <input
                                                            className="lottery-place-input p-1"
                                                            name="bet_numbers" onBlur={(e) => { checkDigits(e, item?.id) }} onChange={(e) => { getInputDataFunction(e, item?.id) }} value={item?.bet_numbers || ""}
                                                            type="text" pattern="" inputMode="" maxLength={betNumbersLength}
                                                            // size={data?.number_digit} 
                                                            placeholder={`Number..`}
                                                            disabled={!data?.id}
                                                            style={{
                                                                color: (colorMode === "dark") ? "var(--white)" : "var(--dark)",
                                                                backgroundColor: (colorMode === "dark") ? "var(--blackish-ash)" : "var(--white)",
                                                                boxShadow: (colorMode === "dark") ? "0 0 4px rgba(0, 0, 0, 0.75)" : "0 0 4px rgba(0, 0, 0, 0.25)",
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="text-center p-0">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <input
                                                            className={"lottery-place-input p-1 " + ((data?.d_stake_lock) ? "placeholder-red" : "")}
                                                            name="d_stake_bet"
                                                            value={item?.d_stake_bet ? item?.d_stake_bet : ""}
                                                            disabled={data?.d_stake_lock}
                                                            onBlur={(e) => { checkAmount(e, item?.id) }} onChange={(e) => { getInputDataFunction(e, item?.id) }}
                                                            type="number" onWheel={(event) => event.target.blur()} placeholder={(data?.d_stake_lock) ? `Lock` : `$D`}
                                                            style={{
                                                                color: (colorMode === "dark") ? "var(--white)" : "var(--dark)",
                                                                backgroundColor: (colorMode === "dark") ? "var(--blackish-ash)" : "var(--white)",
                                                                boxShadow: (colorMode === "dark") ? "0 0 4px rgba(0, 0, 0, 0.75)" : "0 0 4px rgba(0, 0, 0, 0.25)",
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="text-center p-0">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <input
                                                            className={"lottery-place-input p-1 " + ((data?.r_stake_lock) ? "placeholder-red" : "")}
                                                            name="r_stake_bet"
                                                            value={item?.r_stake_bet ? item?.r_stake_bet : ""}
                                                            disabled={data?.r_stake_lock}
                                                            onBlur={(e) => { checkAmount(e, item?.id) }} onChange={(e) => { getInputDataFunction(e, item?.id) }}
                                                            type="number" onWheel={(event) => event.target.blur()} placeholder={(data?.r_stake_lock) ? `Lock` : `$R`}
                                                            style={{
                                                                color: (colorMode === "dark") ? "var(--white)" : "var(--dark)",
                                                                backgroundColor: (colorMode === "dark") ? "var(--blackish-ash)" : "var(--white)",
                                                                boxShadow: (colorMode === "dark") ? "0 0 4px rgba(0, 0, 0, 0.75)" : "0 0 4px rgba(0, 0, 0, 0.25)",
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="col-1 d-flex justify-content-end align-items-center">
                            <div onClick={addRowInTable}
                                className="d-flex justify-content-center align-items-center w-75 py-2"
                                style={{
                                    borderRadius: "5px",
                                    height: "32px",
                                    backgroundColor: (colorMode === "dark") ? "var(--blackish-ash)" : "var(--white)",
                                    boxShadow: (colorMode === "dark") ? "0 0 4px rgba(0, 0, 0, 0.75)" : "0 0 4px rgba(0, 0, 0, 0.25)",
                                }}
                            >
                                <FaPlus
                                    fill={(colorMode === "dark") ? "var(--white)" : "var(--dark)"}
                                    style={{ fontSize: "1.3rem", cursor: "pointer" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row gx-0 justify-content-center align-items-center px-2">
                        <div
                            className={(colorMode === "dark") ? "col-12 blackish-ash-background w-100 text-center rounded py-2 mb-1 text-white custom-shadow" : "col-12 gray-background w-100 text-center rounded py-2 mb-1 text-dark custom-shadow"}
                        >
                            {`Total Amount = $${listTotal ? listTotal.toFixed(2) : "00.00"}`}
                        </div>
                        <div
                            className={(colorMode === "dark") ? "col-12 blackish-ash-background w-100 text-center rounded py-2 mb-1 text-white custom-shadow" : "col-12 gray-background w-100 text-center rounded py-2 mb-1 text-dark custom-shadow"}
                        >
                            {`Discount ${data?.discount_percentage}% = $${discountAmount}`}
                        </div>
                        <div
                            className={(colorMode === "dark") ? "col-12 blackish-ash-background w-100 text-center rounded py-2 mb-1 text-white custom-shadow" : "col-12 gray-background w-100 text-center rounded py-2 mb-1 text-dark custom-shadow"}
                        >
                            {`Bet Amount = $${betAmount}`}
                        </div>
                    </div>

                    <div className="row gx-0 justify-content-center align-items-center px-2">
                        <div className="col-12">
                            <button
                                onClick={lotteryPlaceConfirmationFunction}
                                className={"text-uppercase btn golden-button w-100 rounded my-3"}
                            >
                                {"Place Bet"}
                            </button>
                        </div>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LotteryPlaceModal;