import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { rootUrl, userKey } from "../../../App";

import { useThemeColor } from "../../../hooks/useThemeColor";

import HistoryAccordion from "./HistoryAccordion";

const LotteryHistory = () => {
    const { colorMode } = useThemeColor();

    const [searchParams,] = useSearchParams();
    const game_id = searchParams.get("game_id");
    const [lotteryHistory, setLotteryHistory] = useState(null);
    const [activeKey, setActiveKey] = useState(lotteryHistory?.[0]?.id || null);

    useEffect(() => {
        if (!activeKey && lotteryHistory?.length > 0) {
            setActiveKey(lotteryHistory?.[0]?.id);
        }
    }, [lotteryHistory, activeKey]);

    useEffect(() => {
        let stringValue = localStorage.getItem(userKey);
        let value = JSON.parse(stringValue);

        if (!lotteryHistory && value?.value) {
            // dispatch(setInnerLoaderTrue());

            let fullUrl = `${rootUrl}/lottery/bet-history/${game_id}/`;

            fetch(fullUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Token ${value?.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    if (data?.status === "ok") {
                        // dispatch(setInnerLoaderFalse());
                        setLotteryHistory(data?.data);
                        // setLotteryHistory([true, data?.data]);
                        // window.scrollTo(0, 1);
                        return;
                    }
                    if (data?.status === "failed") {
                        return;
                    }
                })
                .catch(err => {
                    err.json().then(text => {
                    });
                })
                .finally(() => {
                    // dispatch(setInnerLoaderFalse());
                })
        }
    }, [lotteryHistory]);

    // console.log(lotteryHistory);

    return (
        <>
            <div className={(colorMode === "dark") ? "mx-2 my-2 dark-background" : "mx-2 my-2 light-blue-background"}>
                {
                    lotteryHistory &&
                    lotteryHistory?.map((item, index) =>
                        <HistoryAccordion key={item?.id || index} activeKey={activeKey} setActiveKey={setActiveKey} item={item} />
                    )
                }
            </div>
        </>
    );
};

export default LotteryHistory;