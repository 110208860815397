import React from "react";
import "./GamesLeftSideBar.css";
import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";

// import { userDetail } from "../../../Redux/userSlice";
import { rootUrl } from "../../../App";

const allIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/SharedIcons/all-icon.svg";

const GamesLeftSideBar = ({ selectedLeftBarData, setSelectedLeftBarData, leftSideBarData, allGames, setShowableGameList, }) => {

    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    // const user = useSelector(userDetail);

    const changeStateFunc = (item) => {
        // console.log(user?.is_login, item.name);
        setSelectedLeftBarData(item);
        if (item?.provider_code === "ALL") {
            setShowableGameList(allGames);
        } else {
            const fiteredGames = allGames?.filter((filteredItem) => (filteredItem?.p_code === item?.provider_code));
            setShowableGameList(fiteredGames);
        }
        // console.log(item, allGames);
    }
    return (
        <div className="game-left-bar rounded py-0">
            {
                leftSideBarData?.map(item =>
                    <Link
                        key={item?.id}
                        to={item?.to}
                        className={(selectedLeftBarData?.title === item?.title) ? "active rounded custom-shadow mb-2" : "sqrBtn rounded custom-shadow mb-2"}
                        onClick={() => changeStateFunc(item)}
                        style={{ textDecoration: "none" }}
                    >
                        <div className="d-flex flex-column justify-content-center align-items-center m-0 p-0 text-center">
                            {/* <img src={(selectedLeftBarData?.name === item?.name) ? item?.icon1 : item?.icon2} alt={item?.name} /> */}
                            <img src={
                                (item?.title === "All") ? allIcon
                                    : (rootUrl + item?.thumbnail)
                            } alt={item?.title} />
                            <p>{item?.title}</p>
                        </div>
                    </Link>
                )
            }
        </div>
    );
};

export default GamesLeftSideBar;