import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import "./Launcher.css";
import { rootUrl, userKey } from "../../../App";
// import MovableCross from "../../../Components/Shared/MovableCross/MovableCross";

import Swal from "sweetalert2";
import { createPortal } from "react-dom";

import LauncherErrorAlert from "../../../Components/Shared/CustomSweetAlert/LauncherErrorAlert";
import useGameErrors from "../../../hooks/useGameErrors";
import MovableHome from "../../../Components/Shared/MovableHome/MovableHome";

const Launcher = () => {

    const [launchUrl, setLaunchUrl] = useState(null);
    const [customLoader, setCustomLoader] = useState(true);

    const { p_code, p_type, g_code, operator } = useParams();

    const { findErrorByCode, closeErrorAlert, showGameErrorsSweetAlert2, errorAlert, } = useGameErrors();

    useEffect(() => {
        // console.log(p_code, p_type);
        let stringValue = localStorage.getItem(userKey);
        let value = JSON.parse(stringValue);

        if (!launchUrl && value?.value) {
            // dispatch(setInnerLoaderTrue());

            let fullUrl = `${rootUrl}/game/launchGame/${p_code}/${p_type}/?game_id=${(g_code === "0") ? "" : g_code}&operator=${operator === "0" ? "" : operator}`;

            fetch(fullUrl, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Token ${value?.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    if (data?.status === "ok") {
                        // dispatch(setInnerLoaderFalse());
                        setLaunchUrl(data?.data?.gameUrl);
                        // window.scrollTo(0, 1);
                        return;
                    }
                    if (data?.status === "failed") {
                        // setLaunchUrl(null);
                        const matchedError = findErrorByCode(data?.errCode);
                        if (matchedError) {
                            showGameErrorsSweetAlert2(matchedError, true);
                            // navigate(-1);
                        }
                        return;
                    }
                })
                .catch(err => {
                    err.json().then(text => {
                    });
                })
                .finally(() => {
                    // dispatch(setInnerLoaderFalse());
                })
        }
    }, []);

    const hideLoader = () => {
        setCustomLoader(true);
        setTimeout(async () => {
            setCustomLoader(false);
        }, 5000);
    }

    return (
        <>
            {/* <div className="sticky-top">
                <LauncherNavigation
                    rendered={launchUrl}
                />
            </div> */}
            <div
                // style={{ height: "calc(100dvh - 38px)" }}
                style={{ height: "100dvh" }}
            >

                <div
                    className="w-100 h-100"
                    style={{
                        position: "relative",
                        // overflow: "hidden",
                        // width: "100%",
                        // paddingTop: "56.25%",
                    }}>
                    {
                        ((customLoader) || (launchUrl === "null")) &&
                        <div style={{
                            position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                            zIndex: 1
                        }}>
                            <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                                <Spinner animation="grow" variant="secondary" />
                                <h6>{"Just wait a while..."}</h6>
                            </div>
                        </div>
                    }
                    {
                        ((launchUrl !== "null") || (!customLoader)) &&
                        < iframe
                            src={launchUrl}
                            title="gameIframe"
                            name="gameIframe"
                            id="gameIframe"
                            frameBorder="0"
                            allow="autoplay"
                            allowFullScreen={true}
                            scrolling="auto"
                            width="100%"
                            height="100%"
                            marginWidth="0"
                            marginHeight="0"
                            style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0, minHeight: "100dvh", minWidth: "100%", height: "100dvh", overflow: "hidden", display: "block", zIndex: 3, }}
                            onLoad={() => hideLoader}
                        ></ iframe>
                    }
                </div>
            </div >
            {/* <MovableCross item={p_code} launched={(launchUrl === null) ? false : true} /> */}
            {
                (errorAlert?.visible)
                &&
                createPortal(
                    <LauncherErrorAlert close={() => closeErrorAlert(true)} item={errorAlert?.item} />,
                    Swal.getHtmlContainer() || document.body
                )
            }
            {
                // operator === "9imax" &&
                <MovableHome />
            }
        </>
    );
};

export default Launcher;