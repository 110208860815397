import React, { useState, useEffect } from "react";
import { useThemeColor } from "../../../hooks/useThemeColor";

import { rootUrl, userKey } from "../../../App";
import LotteryPlaceModal from "./LotteryPlaceModal";

const liveStreamDark = process.env.PUBLIC_URL + "/Assets/Images/Icons/LotteryLauncherIcons/live-stream-dark.svg";
const liveStreamLight = process.env.PUBLIC_URL + "/Assets/Images/Icons/LotteryLauncherIcons/live-stream-dark.svg";

const timerDark = process.env.PUBLIC_URL + "/Assets/Images/Icons/LotteryLauncherIcons/timer-dark.svg";
const timerLight = process.env.PUBLIC_URL + "/Assets/Images/Icons/LotteryLauncherIcons/timer-dark.svg";

const LotteryItem = ({ gameItem, setLotteryData, setShowLiveTV, }) => {
    const [showLotteryModal, setShowLotteryModal] = useState([false, {}]);
    const { colorMode } = useThemeColor();

    const calculateRemainingTime = (targetTimeString) => {
        // Get current time
        const now = new Date();

        // Parse the target time string into a Date object
        const targetTime = new Date(targetTimeString);

        // Calculate the time difference in milliseconds
        let timeDifference = targetTime.getTime() - now.getTime();

        // If the time difference is negative, set to zero
        if (timeDifference < 0) {
            return {
                days: "00",
                hours: "00",
                minutes: "00",
                seconds: "00"
            };
        }

        // Convert the time difference to days, hours, minutes, and seconds
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        timeDifference %= (1000 * 60 * 60 * 24);
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        timeDifference %= (1000 * 60 * 60);
        const minutes = Math.floor(timeDifference / (1000 * 60));
        timeDifference %= (1000 * 60);
        const seconds = Math.floor(timeDifference / 1000);

        // Return the remaining time as an object
        return {
            days: days < 10 ? "0" + days : days,
            hours: hours < 10 ? "0" + hours : hours,
            minutes: minutes < 10 ? "0" + minutes : minutes,
            seconds: seconds < 10 ? "0" + seconds : seconds
        };
    }

    const [remainingTime, setRemainingTime] = useState(calculateRemainingTime(gameItem?.events?.[0]?.end_time));

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime(calculateRemainingTime(gameItem?.events?.[0]?.end_time));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if ((+remainingTime?.days === 0) && (+remainingTime?.hours === 0) && (+remainingTime?.minutes === 0) && (+remainingTime?.seconds === 0)) {

            let stringValue = localStorage.getItem(userKey);
            if (stringValue !== null) {
                let value = JSON.parse(stringValue);

                // Using map to extract ids
                const idsArray = gameItem?.events?.map(obj => obj?.id);

                const payLoad = {
                    e_ids: idsArray
                }

                fetch(`${rootUrl}/lottery/deActivate-events/`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": `Token ${value?.value}`,
                    },
                    body: JSON.stringify(payLoad)
                })
                    .then((res) => {
                        if (!res.ok) {
                            throw res;
                        } else {
                            setLotteryData(null);
                            // console.log(res);
                        }
                    })
                    .catch((err) => {
                        console.error(err?.detail);
                    });
            }
        }
    }, [remainingTime, gameItem]);

    const openLotteryPlaceModal = (item) => {
        // console.log(item);
        let stringValue = localStorage.getItem(userKey);
        if (stringValue === null) {
            // navigate("/login");
            return;
        } else {
            setShowLotteryModal([true, item]);
        }
    }

    return (
        <div className="game-item p-2">
            <div className="row gx-0 d-flex justify-content-end align-items-end pb-2">
                <div className="col-10 col-md-10">
                    <div className="row gx-0 d-flex justify-content-between align-items-end">
                        <div className="col">
                            <div
                                onClick={() => setShowLiveTV(gameItem?.events?.[0])}
                                className={"d-flex justify-content-center align-items-center rounded-pill py-1 mx-1 pointer " + ((colorMode === "dark") ? "blackish-ash-background text-white" : "gray-background text-dark")}>
                                <img className="mb-0"
                                    src={colorMode === "dark" ? liveStreamDark : liveStreamLight} alt="live-stream"
                                    width={"15"} height={"15"}
                                />
                                <p className="mb-0 pb-0 ps-2">
                                    {"Live Event"}
                                </p>
                            </div>
                        </div>
                        <div className="col">
                            <div className={"d-flex justify-content-center align-items-center red-text rounded-pill py-1 mx-1 " + ((colorMode === "dark") ? "blackish-ash-background" : "gray-background")}>
                                <img className="mb-0"
                                    src={colorMode === "dark" ? timerDark : timerLight} alt="timer"
                                    width={"15"} height={"15"}
                                />
                                <p className="mb-0 pb-0 ps-2">
                                    {/* {remainingTime?.hours}:{remainingTime?.minutes}:{remainingTime?.seconds} */}
                                    {remainingTime?.days}d {remainingTime?.hours}h {remainingTime?.minutes}m {remainingTime?.seconds}s
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="radius-10 blackish-ash-background">
                <div className={(colorMode === "dark") ? "row gx-0 d-flex dark-background golden-text radius-10 py-1 px-3" : "row gx-0 d-flex dark-background golden-text radius-10 py-1 px-3"}>
                    <div className="col-7 col-md-8 d-flex justify-content-start align-items-center">
                        <p className="mb-0 pb-0">
                            {"Game Type"}
                        </p>
                    </div>
                    <div className="col-5 col-md-4"
                        style={{ borderLeft: (colorMode === "dark") ? "1px solid var(--white)" : "1px solid var(--white)", }}
                    >
                        <div className="row gx-0 d-flex justify-content-between">
                            <div className="col">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <p className="mb-0 pb-0">
                                        {"D Stake"}
                                    </p>
                                    <small className="mb-0 pb-0 red-text">
                                        {"Rate"}
                                    </small>
                                </div>
                            </div>
                            <div className="col d-flex justify-content-end align-items-center">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <p className="mb-0 pb-0">
                                        {"R Stake"}
                                    </p>
                                    <small className="mb-0 pb-0 red-text">
                                        {"Rate"}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (gameItem?.events?.length) &&
                    gameItem?.events?.map((item, index) =>
                        <div
                            key={item?.id || index}
                            onClick={() => openLotteryPlaceModal(item)}
                            className="row gx-0 d-flex justify-content-center align-items-center individual-type pointer px-3"
                        >
                            <div className="col-7 col-md-8 text-start text-uppercase text-white">
                                <p className="mb-0 pb-0">
                                    {item?.game_type?.name}
                                </p>
                            </div>
                            <div className="col-5 col-md-4">
                                <div className="row gx-0 d-flex stake-font">
                                    <div className="col-6">
                                        <div className="d-flex justify-content-center align-items-center golden-background rounded px-2 py-1 my-1 me-2">
                                            {/* <p className="mb-0 pb-0">
                                                {"1 = "}
                                            </p> */}
                                            <div className="row gx-0 d-flex flex-column stake-line-height">
                                                <p className="mb-0 pb-0">
                                                    {item?.d_stake_rate}
                                                </p>
                                                <p className="mb-0 pb-0 percentage-font">
                                                    {item?.discount_percentage}%
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 text-white">
                                        <div className="d-flex justify-content-center align-items-center light-green-background rounded px-2 py-1 my-1 ms-2">
                                            {/* <p className="mb-0 pb-0">
                                                {"1 ="}
                                            </p> */}
                                            <div className="row gx-0 d-flex flex-column stake-line-height">
                                                <p className="mb-0 pb-0">
                                                    {item?.r_stake_rate}
                                                </p>
                                                <p className="mb-0 pb-0 percentage-font">
                                                    {item?.discount_percentage}%
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            {
                showLotteryModal[0] &&
                <LotteryPlaceModal
                    show={showLotteryModal[0]}
                    close={() => { setShowLotteryModal([false, {}]) }}
                    data={showLotteryModal[1]}
                // postableData={details[selectedDetails]}
                // calculate={calculate}
                // setCalculate={setCalculate}
                // exchangeMinimum={oddsMinimum}
                // exchangeMaximum={oddsMaximum}
                // getOddsDetails={getOddsDetails}
                />
            }
        </div>
    );
};

export default LotteryItem;