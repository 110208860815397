import React from 'react';


// auth pages
const Login = React.lazy(() => import('../Pages/Auth/Login/Login'));
const FaceID = React.lazy(() => import('../Pages/Auth/Login/FaceID'));
const Credential = React.lazy(() => import('../Pages/Auth/Login/Credential'));

const ForgotPin = React.lazy(() => import('../Pages/Auth/ForgotPin/ForgotPin'));
const ChangePin = React.lazy(() => import('../Pages/ChangePin/ChangePin'));
const OtpSettings = React.lazy(() => import('../Pages/OtpSettings/OtpSettings'));

const Register = React.lazy(() => import('../Pages/Auth/Register/Register'));
const RegMailVerify = React.lazy(() => import('../Pages/Auth/Register/RegMailVerify'));
const OneClick = React.lazy(() => import('../Pages/Auth/Register/OneClick'));
const SignUp = React.lazy(() => import('../Pages/Auth/Register/SignUp'));
const SocialRegistration = React.lazy(() => import('../Pages/Auth/Register/SocialRegistration'));
const AffiliateRegister = React.lazy(() => import('../Pages/Auth/Register/AffiliateRegister'));
const AffiliateRegMailVerify = React.lazy(() => import('../Pages/Auth/Register/AffiliateRegMailVerify'));

const Promotion = React.lazy(() => import('../Pages/Promotion/Promotion'));
const Affiliate = React.lazy(() => import('../Pages/Affiliate/Affiliate'));
const Languages = React.lazy(() => import('../Pages/Languages/Languages'));
const LiveTV = React.lazy(() => import('../Pages/LiveTV/LiveTV'));
const Notifications = React.lazy(() => import('../Pages/Notifications/Notifications'));
const UserProfile = React.lazy(() => import('../Pages/UserProfile/UserProfile'));
const EditProfile = React.lazy(() => import('../Pages/EditProfile/EditProfile'));
const More = React.lazy(() => import('../Pages/More/More'));
const Referrals = React.lazy(() => import('../Pages/Referrals/Referrals'));
const TC = React.lazy(() => import('../Pages/TC/TC'));

const Deposit = React.lazy(() => import('../Pages/TransactionPages/Deposit/Deposit.js'));
const Withdrawal = React.lazy(() => import('../Pages/TransactionPages/Withdrawal/Withdrawal.js'));
const Transfer = React.lazy(() => import('../Pages/TransactionPages/Transfer/Transfer.js'));
const Convert = React.lazy(() => import('../Pages/TransactionPages/Convert/Convert.js'));

const Bonus = React.lazy(() => import('../Pages/Bonus/Bonus.js'));

const DepositTable = React.lazy(() => import('../Pages/TransactionPages/TransactionTables/DepositTable'));
const WithdrawalTable = React.lazy(() => import('../Pages/TransactionPages/TransactionTables/WithdrawalTable'));
const TransactionsTable = React.lazy(() => import('../Pages/TransactionPages/TransactionTables/TransactionsTable'));

// const PerfectMoney = React.lazy(() => import('../Pages/TransactionPages/Deposit/PerfectMoney/PerfectMoneyPayment.js'));
const PerfectMoneyRedirection = React.lazy(() => import('../Pages/TransactionPages/Deposit/PerfectMoney/PerfectMoneyPaymentRedirect.js'));
const PerfectMoneyStatus = React.lazy(() => import('../Pages/TransactionPages/Deposit/PerfectMoney/PerfectMoneyPaymentStatus.js'));
const PerfectMoneyCancel = React.lazy(() => import('../Pages/TransactionPages/Deposit/PerfectMoney/PerfectMoneyNoPayment.js'));

const Sports = React.lazy(() => import('../Pages/GamePages/Sports/Sports'));
const Casino = React.lazy(() => import('../Pages/GamePages/Casino/Casino'));
const Slots = React.lazy(() => import('../Pages/GamePages/Slots/Slots'));
const Fishing = React.lazy(() => import('../Pages/GamePages/Fishing/Fishing'));
const Table = React.lazy(() => import('../Pages/GamePages/Table/Table'));
const Lottery = React.lazy(() => import('../Pages/GamePages/Lottery/Lottery'));

export const routesListAuth = [
    {
        id: 0,
        name: "Login",
        path: "/login",
        pageTitle: "Login",
        component: <Credential />,
        public: true,
    },
    {
        id: 1,
        name: "Face ID",
        path: "/faceID",
        pageTitle: "Face ID",
        component: <FaceID />,
        public: true,
    },
    {
        id: 2,
        name: "Credential",
        path: "/credential",
        pageTitle: "Credential",
        component: <Credential />,
        public: true,
    },
    {
        id: 3,
        name: "Forgot Pin",
        path: "/forgot-pin",
        pageTitle: "Forgot Pin",
        component: <ForgotPin />,
        public: true,
    },
    {
        id: 4,
        name: "Registration",
        path: "/register",
        pageTitle: "Registration",
        component: <Register />,
        public: true,
    },
    {
        id: 5,
        name: "Mail Verification",
        path: "/verify-mail",
        pageTitle: "VERIFY YOUR ACCOUNT",
        component: <RegMailVerify />,
    },
    {
        id: 6,
        name: "One Click Registration",
        path: "/one-click",
        pageTitle: "One Click",
        component: <OneClick />,
        public: true,
    },
    {
        id: 7,
        name: "Registration",
        path: "/registration",
        pageTitle: "Registration",
        component: <SignUp />,
        public: true,
    },
    {
        id: 8,
        name: "Social Registration",
        path: "/social-registration",
        pageTitle: "Social Registration",
        component: <SocialRegistration />,
        public: true,
    },
    {
        id: 9,
        name: "Registration",
        path: "/affiliate-register",
        pageTitle: "Registration",
        component: <AffiliateRegister />,
        public: true,
    },
    {
        id: 10,
        name: "Mail Verification",
        path: "/affiliate-verify-mail",
        pageTitle: "VERIFY YOUR ACCOUNT",
        component: <AffiliateRegMailVerify />,
    },
];

export const routesListWithBackButtonPageName = [
    {
        id: 0,
        name: "Profile",
        path: "/user-profile",
        pageTitle: "Profile",
        component: <UserProfile />,
        private: true,
    },
    {
        id: 1,
        name: "Account",
        path: "/account",
        pageTitle: "Account",
        component: <More />,
        private: true,
    },
    // {
    //     id: 2,
    //     name: "Notifications",
    //     path: "/notifications",
    //     pageTitle: "Notifications",
    //     component: <Notifications />,
    //     private: true,
    // },
    {
        id: 3,
        name: "Promotion",
        path: "/promotion",
        pageTitle: "Promotion",
        component: <Promotion />,
    },
    {
        id: 4,
        name: "Affiliate",
        path: "/affiliate",
        pageTitle: "Affiliate",
        component: <Affiliate />,
    },
    {
        id: 5,
        name: "Languages",
        path: "/languages",
        pageTitle: "Languages",
        component: <Languages />,
    },
    {
        id: 6,
        name: "Webinar",
        path: "/webinar",
        pageTitle: "Webinar",
        component: <LiveTV />,
    },
    {
        id: 7,
        name: "Edit Profile",
        path: "/edit-profile",
        pageTitle: "Edit Profile",
        component: <EditProfile />,
        private: true,
    },
    {
        id: 8,
        name: "Referral",
        path: "/referral",
        pageTitle: "Referral",
        component: <Referrals />,
    },
    {
        id: 9,
        name: "Terms & Conditions",
        path: "/terms-conditions",
        pageTitle: "Terms & Conditions",
        component: <TC from={`/statics/terms-and-condition/`} />,
    },
    {
        id: 10,
        name: "Privacy Policy",
        path: "/privacy-policy",
        pageTitle: "Privacy Policy",
        component: <TC from={`/statics/privacy-and-policy/`} />,
    },
    {
        id: 10,
        name: "Change Pin",
        path: "/change-pin",
        pageTitle: "Change Pin",
        component: <ChangePin />,
        private: true,
    },
    {
        id: 11,
        name: "Otp Settings",
        path: "/otp-settings",
        pageTitle: "Otp Settings",
        component: <OtpSettings />,
        private: true,
    },
    // {
    //     id: 12,
    //     name: "Deposit",
    //     path: "/deposit",
    //     pageTitle: "Deposit",
    //     component: <Deposit />,
    //     private: true,
    // },
    // {
    //     id: 13,
    //     name: "Withdrawal",
    //     path: "/withdrawal",
    //     pageTitle: "Withdrawal",
    //     component: <Withdrawal />,
    //     private: true,
    // },
    {
        id: 14,
        name: "P2P Transfer",
        path: "/transfer",
        pageTitle: "P2P Transfer",
        component: <Transfer />,
        private: true,
    },
    {
        id: 15,
        name: "Convert",
        path: "/convert",
        pageTitle: "Convert",
        component: <Convert />,
        private: true,
    },
    {
        id: 16,
        name: "",
        path: "/perfectMoneyPaymentRedirect",
        pageTitle: "",
        component: <PerfectMoneyRedirection />,
        private: true,
    },
    {
        id: 17,
        name: "",
        path: "/perfectMoneyPaymentStatus",
        pageTitle: "",
        component: <PerfectMoneyStatus />,
        private: true,
    },
    {
        id: 18,
        name: "",
        path: "/perfectMoneyNoPayment",
        pageTitle: "",
        component: <PerfectMoneyCancel />,
        private: true,
    },
    {
        id: 19,
        name: "Deposit History",
        path: "/deposit-history",
        pageTitle: "History",
        component: <DepositTable />,
        private: true,
    },
    {
        id: 20,
        name: "Withdrawal History",
        path: "/withdrawal-history",
        pageTitle: "History",
        component: <WithdrawalTable />,
        private: true,
    },
    {
        id: 21,
        name: "Transactions",
        path: "/transactions",
        pageTitle: "Transactions",
        component: <TransactionsTable />,
        private: true,
    },
    {
        id: 22,
        name: "Bonus",
        path: "/bonus/:id",
        pageTitle: "Bonus",
        component: <Bonus />,
        private: false,
    },
];

export const exceptionalPages = [
    {
        id: 2,
        name: "Notifications",
        path: "/notifications",
        pageTitle: "Notifications",
        component: <Notifications />,
        private: true,
    },
    {
        id: 12,
        name: "Deposit",
        path: "/deposit",
        pageTitle: "Deposit",
        component: <Deposit />,
        private: true,
    },
    {
        id: 13,
        name: "Withdrawal",
        path: "/withdrawal",
        pageTitle: "Withdrawal",
        component: <Withdrawal />,
        private: true,
    },
];

export const gamePages = [
    {
        id: 0,
        name: "Sports",
        path: "/sports",
        pageTitle: "Sports",
        component: <Sports />,
    }, {
        id: 1,
        name: "Casino",
        path: "/casino",
        pageTitle: "Casino",
        component: <Casino />,
    },
    {
        id: 2,
        name: "Slots",
        path: "/slots",
        pageTitle: "Slots",
        component: <Slots />,
    },
    {
        id: 3,
        name: "Fishing",
        path: "/fishing",
        pageTitle: "Fishing",
        component: <Fishing />,
    },
    {
        id: 4,
        name: "Table",
        path: "/table",
        pageTitle: "Table",
        component: <Table />,
    },
    {
        id: 5,
        name: "Lottery",
        path: "/lottery",
        pageTitle: "Lottery",
        component: <Lottery />,
    },
];