import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { rootUrl, userKey } from "../App";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "./loaderSlice";

import { showCustomSweetAlert } from "./customSweetAlertSlice";

//user_login
export const fetchLoginUserAuth = createAsyncThunk("user/fetchLoginUserAuth", async (payload, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
        // let parentUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;
        // let url_val = parentUrl.split('/');
        // let make_url = url_val[0] + '//' + url_val[2] + '/';

        // let newData = { ...payload, request_origin: 'betkingly', user_base_origin: make_url };
        let newData = { ...payload };

        const response = await fetch(`${rootUrl}/auth/user/login/`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newData)
        });

        let data = await response.json();
        // console.log(data);
        if (data?.errors || data?.login_error) {
            dispatch(showCustomSweetAlert({
                type: "error",
                message: `${data.errors || data.login_error}`
            }));
            return rejectWithValue(data.error || data.login_error);
        } else {
            localStorage.setItem(userKey, JSON.stringify({ value: data?.data?.token }));
            sessionStorage.setItem(userKey, JSON.stringify({ value: data?.data?.token }));
            dispatch(fetchLoginUserData());
            return data;
        }
    } catch (error) {
        if (error.message === 'Failed to fetch') {
            dispatch(showCustomSweetAlert(`Currently not available!!!`));
            throw rejectWithValue('Currently not available!!!');
        } else {
            dispatch(showCustomSweetAlert({
                type: "error",
                message: `${error?.message}`
            }));
            throw rejectWithValue(error?.message);
        }
    }
})


//get_user_data
export const fetchLoginUserData = createAsyncThunk("user/fetchLoginUserData", async (payload, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    let stringValue = localStorage.getItem(userKey);
    if (stringValue === null) {
        localStorage.removeItem(userKey);
        const currentUser = {
            is_login: false,
            userData: null,
        };
        throw rejectWithValue(currentUser);
    }

    try {
        dispatch(setInnerLoaderTrue());
        let value = JSON.parse(stringValue);

        const response = await fetch(`${rootUrl}/auth/user/profile/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${value.value}`
            },
        });

        if (!response.ok) throw response;
        else {
            let { data } = await response.json();
            // console.log(data);
            // dispatch(fetchLoginUserWallet());
            return data;
        }
    }
    catch (error) {
        let errorData = await error.json();
        if (errorData?.error) {
            throw rejectWithValue(errorData?.error);
        } else if (errorData?.detail === "Invalid token.") {
            window.open(JSON.parse(stringValue).loginUrl, "_parent");
            localStorage.removeItem(userKey);
            // localStorage.removeItem("campaignVisible");
            throw rejectWithValue(errorData?.detail);
        } else {
            throw rejectWithValue(error.statusText);
        }
    }
    finally {
        dispatch(setInnerLoaderFalse());
    }
})


//get_user_wallet
export const fetchLoginUserWallet = createAsyncThunk("user/fetchLoginUserWallet", async (payload, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    let stringValue = localStorage.getItem(userKey)
    if (stringValue === null) {
        localStorage.removeItem(userKey);
        const currentUser = {
            is_login: false,
            userData: null,
        };
        return currentUser;
    }
    try {
        // dispatch(setInnerLoaderTrue());
        let value = JSON.parse(stringValue);
        const response = await fetch(`${rootUrl}/auth/user-balance/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${value.value}`,
            },
        });
        if (!response.ok) {
            throw response;
        } else {
            let { data } = await response.json();
            // console.log(data);
            return data;
        }
    }
    catch (error) {
        let errorData = await error.json();
        if (errorData?.error) {
            throw rejectWithValue(errorData?.error);
        } else if (errorData?.detail === "Invalid token.") {
            localStorage.removeItem(userKey);
            // localStorage.removeItem("campaignVisible");
            throw rejectWithValue(errorData?.detail);
        } else {
            throw rejectWithValue(error.statusText);
        }
    }
    finally {
        // dispatch(setInnerLoaderFalse());
    }
})


//user logout
export const fetchLogoutUser = createAsyncThunk("user/fetchLogoutUser", async (payload, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    let stringValue = localStorage.getItem(userKey);
    if (stringValue === null) {
        localStorage.removeItem(userKey);
        return null;
    }

    dispatch(setInnerLoaderTrue());
    try {
        let value = JSON.parse(stringValue);
        const response = await fetch(`${rootUrl}/auth/user/logout/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${value?.value}`,
            }
        });
        if (!response.ok) throw response;
        else {
            localStorage.removeItem(userKey);
            sessionStorage.removeItem(userKey);
            // localStorage.removeItem("campaignVisible");
            return null;
        }
    } catch (error) {
        if (error.message === 'Failed to fetch') {
            dispatch(showCustomSweetAlert({
                type: "error",
                message: `Internet Connection Error !!! `
            }));
            throw rejectWithValue('Internet Connection Error !!! ');
        }

        let errorData = await error.json();
        if (errorData?.error) {
            throw rejectWithValue(errorData?.error);
        } else if (errorData?.detail === "Invalid token.") {
            window.open(JSON.parse(stringValue).loginUrl, "_parent");
            localStorage.removeItem(userKey);
            // localStorage.removeItem("campaignVisible");
            return null;
        } else {
            throw rejectWithValue(error.statusText);
        }
    } finally {
        dispatch(setInnerLoaderFalse());
    }

})

const userSlice = createSlice({
    name: 'user',
    initialState: {
        status: "loading",
        registrationData: null,
        login: false,
        userData: null,
        currentUserWallet: {},
    },

    reducers: {
        setRegistrationData: (state, action) => {
            state.registrationData = action.payload;
        },

    },

    extraReducers: {
        //user_login
        [fetchLoginUserAuth.pending]: (state, action) => {
            state.status = "loading"
        },
        [fetchLoginUserAuth.fulfilled]: (state, action) => {
            state.status = "success";
        },
        [fetchLoginUserAuth.rejected]: (state, action) => {
            state.status = "failed";
        },

        //user_data
        [fetchLoginUserData.pending]: (state, action) => {
            state.status = "loading"
        },
        [fetchLoginUserData.fulfilled]: (state, action) => {
            state.status = "success";
            const { payload } = action;
            state.login = true;
            state.userData = payload;
        },
        [fetchLoginUserData.rejected]: (state, action) => {
            state.status = "failed";
        },

        //get_login_user_wallet
        [fetchLoginUserWallet.pending]: (state, action) => {
            // state.status = "loading";
        },
        [fetchLoginUserWallet.fulfilled]: (state, action) => {
            state.status = "success";
            const { payload } = action;
            state.currentUserWallet = payload;
        },
        [fetchLoginUserWallet.rejected]: (state, action) => {
            state.status = "failed";
            // const { payload } = action;
        },

        //user_log_out
        [fetchLogoutUser.pending]: (state, action) => {
            state.status = "loading";
        },
        [fetchLogoutUser.fulfilled]: (state, action) => {
            state.status = "success";
            const { payload } = action;
            state.login = false;
            state.userData = payload;
        },
        [fetchLogoutUser.rejected]: (state, action) => {
            state.status = "failed";
            // const { payload } = action;
        },
    }
});

const loginState = state => state.user.login;
const userInfo = state => state.user.userData;

export const userDetail = createSelector([loginState, userInfo], (login, userData) => {
    // console.log(loginState, userInfo, login, userData);

    // do something with login, and userData, and return a result
    let data = {
        is_login: login,
        user: { ...userData, noti_username: userData?.username, username: userData?.username.slice(2) },
    }
    return data;
})

// export const userDetail = state => {
//     // console.log(state);
//     let data = {
//         is_login: state.user.login,
//         user: state.user.userData,
//     }
//     return data;
// };

export const userLoading = state => state.user.status;
export const userRegConfirmData = state => state.user.registrationData;

export const { setRegistrationData } = userSlice.actions;

export const currentUserWallet = state => state.user.currentUserWallet;

export default userSlice.reducer;