import React, { useEffect, useState } from "react";
import "./DynamicGames.css";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useStatics } from "../../../hooks/useStatics";
import { rootUrl } from "../../../App";
import { useSliderCardMenu } from "../../../hooks/useSliderCardMenu";
import GamesLeftSideBar from "../../../Components/Shared/LeftSideBar/GamesLeftSideBar";
import { useGameItems } from "../../../hooks/useGameItems";
import GameCard from "../../../Components/Shared/CustomCards/GameCard/GameCard";
import Loader from "../../../Components/Shared/Loader/Loader";

const Footer = React.lazy(() => import("../../../Components/Shared/Footer/Footer"));

const gameDefaultProvidersData = [
    {
        "id": 0,
        "title": "All",
        "provider_name": "ALL",
        "provider_code": "ALL",
        "status": true,
        "thumbnail": null,
        "game_imgfile_name": null,
        "is_provide_gamelist": false,
        "transfer_min_limit": "N/A",
        "currency": undefined,
        "currency_rate": undefined
    },
];

const DynamicGames = ({ currentStaticGameData, installableBtnRef }) => {
    const { sliderImgList } = useStatics();
    const { sliderCardMenuData } = useSliderCardMenu();

    // providers
    const currentGameData = sliderCardMenuData?.filter((filteredItem) => (filteredItem?.page?.name === sliderImgList?.[0]?.page_list_id?.name));
    const providers = ((currentGameData?.[0])?.game_provider) || [];
    const providerList = [...gameDefaultProvidersData, ...providers];

    // Extract the providers arrays and flatten them into a single array
    const allProviders = sliderCardMenuData?.flatMap(obj => obj?.game_provider);

    // Create a Map to store unique providers based on owner
    const uniqueProvidersMap = new Map();

    // Iterate over each providers object and add it to the Map if the provider_name is not already present
    allProviders?.forEach(provider => {
        if (!uniqueProvidersMap.has(provider?.provider_code)) {
            uniqueProvidersMap.set(provider?.provider_code, provider);
        }
    });

    // Convert the Map values back to an array
    const uniqueProvidersArray = Array.from(uniqueProvidersMap.values());

    // console.log(sliderImgList, currentGameData, providers, providerList);
    // console.log(currentGameData, providerList);

    const [selectedLeftBarData, setSelectedLeftBarData] = useState(providerList?.[0]);

    // games
    const gameType = ((currentGameData?.[0])?.game_type_code) || null;
    const { allGames } = useGameItems(gameType);
    const [showableGameList, setShowableGameList] = useState(allGames);

    const pageSize = 10;
    const [visibleImages, setVisibleImages] = useState(10); // Initial number of visible images
    const [hasMore, setHasMore] = useState(true);

    const handleLoadMore = () => {
        const newVisibleImages = visibleImages + pageSize;
        // console.log(newVisibleImages, allGames?.length, showableGameList?.length);
        if (newVisibleImages >= (allGames?.length || showableGameList?.length)) {
            setHasMore(false);
        }
        setVisibleImages(newVisibleImages);
    };

    useEffect(() => {
        // setShowableGameList(showableGameList?.slice(0, visibleImages));
        setTimeout(() => {
            handleLoadMore();
        }, 500);
    }, [visibleImages]);

    // Assuming gameCardData is the array of all images
    // const visibleImageData = gameCardData?.slice(0, visibleImages);

    // console.log(gameType);
    // console.log(allGames);

    // const selectProviderGameItems = (item) => {
    //     // console.log(item);
    // }

    return (
        <>
            <div className="row gx-0 py-0 d-flex justify-content-center align-items-center">
                <div className="col-12">
                    {
                        (sliderImgList?.length > 0) &&
                        <div className="position-relative">
                            <img className="w-100 dynamic-games-banner" src={rootUrl + sliderImgList[0]?.image} alt={sliderImgList[0]?.alt_text} height={""} />
                            <div className="dynamic-games-overlay"></div>
                        </div>
                    }

                </div>
            </div>

            <section className="mx-2 my-2">
                <div className="row g-0">
                    <div className="col-12">
                        <div className="game-title-area">
                            <h6 className="text-start game-title rounded ps-2 ms-1 mb-0">
                                {currentStaticGameData?.title}
                            </h6>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mx-2 my-2">
                <div className="row g-0 gap">
                    <aside className="col-2"
                    >
                        {/* {"Side Bar"} */}
                        {
                            ((providerList)?.length > 0) &&

                            <GamesLeftSideBar
                                selectedLeftBarData={selectedLeftBarData}
                                setSelectedLeftBarData={setSelectedLeftBarData}
                                leftSideBarData={providerList}
                                allGames={allGames}
                                setShowableGameList={setShowableGameList}
                            />
                        }
                    </aside>
                    <div className="col-10"
                    >
                        <InfiniteScroll
                            dataLength={allGames?.length || showableGameList?.length || visibleImages}
                            next={handleLoadMore}
                            hasMore={hasMore}
                        // loader={<h4>...</h4>}
                        // endMessage={
                        //     <p style={{ textAlign: 'center' }}>
                        //         <b>Yay! You have seen it all</b>
                        //     </p>
                        // }
                        >
                            {/* {"Games or Content"} */}
                            {
                                (showableGameList || allGames)
                                &&

                                <GameCard gameCardData={showableGameList?.slice(0, visibleImages) || allGames?.slice(0, visibleImages)} />
                            }
                        </InfiniteScroll>
                    </div>
                </div>
            </section >

            <footer>
                <Footer installableBtnRef={installableBtnRef} providersList={uniqueProvidersArray} />
            </footer>
            <Loader />
        </>
    );
};

export default DynamicGames;